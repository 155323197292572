.site {
    margin: 0.5rem 0;
    border: 1px solid grey;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.details {
    flex-grow: 2;
    position: relative;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
}

.indicator {
    background-color: transparent;
    width: 1rem;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.25rem;
}

.links a {
    font-size: 0.9rem;
    text-decoration: none;
    margin: 0 0.5rem;
}

.editButton {
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 1rem;
    visibility: hidden;
}

.editButton.visible {
    visibility: visible;
}

.name {
    font-size: 1.1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.status,
.tasks {
    font-size: 0.8rem;
    margin-top: 0.2rem;
}

.version {
    font-size: 0.8rem;
    visibility: hidden;
}

.version a{
    font-size: 0.8rem;
    text-decoration: none;
}

.version.visible {
    visibility: visible;
}

.failed .indicator {
    background-color: #f76262;
}

.healthy .indicator {
    background-color: #4dd599;
}

.neverBuilt .indicator {
    background-color: lightgray;
}

.launching .indicator {
    background: repeating-linear-gradient(
            -45deg,
            #4dd599,
            #4dd599 5px,
            transparent 5px,
            transparent 10px
    );
}

.building .indicator {
    background: repeating-linear-gradient(
            -45deg,
            lightskyblue,
            lightskyblue 5px,
            transparent 5px,
            transparent 10px
    );
}

.failedInProgress .indicator {
    background: repeating-linear-gradient(
            -45deg,
            #f76262,
            #f76262 5px,
            transparent 5px,
            transparent 10px
    );
}
