* {
    background: none;
    border: none;
    box-sizing: border-box;
    font-size: 1rem;
    font-family: 'Open Sans', "Helvetica", "Arial", sans-serif;
    font-weight: normal;
    font-style: normal;
    margin: 0;
    padding: 0;
}

b {
    font-weight: bold;
}

i {
    font-style: italic;
}

html, body {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fafafa;
}

body {
    width: 80%;
    max-width: 60rem;
    margin: 0 auto;
    padding: 1rem 0;
}

:global(.sr-only) {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
